import React, { useEffect, useState } from 'react';
import { MdArchive, MdCancel, MdDelete, MdDownload, MdEdit, MdOutlineRestorePage, MdSave, MdSearch, MdUploadFile } from 'react-icons/md';
import AgendaNavbar from '../components/AgendaNavbar';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { fetchAnagraficaById, createAnagrafica, updateAnagrafica, deleteAnagrafica, archiveAnagrafica, unarchiveAnagrafica } from '../redux/slices/anagraficaSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import IAnagrafica, { exampleAnagrafica } from '../types/IAnagrafica';
import { isValidCF, todayStr } from '../utils/functions';
import Popup from '../components/popup/Popup';
import { deleteDocumento } from '../redux/slices/documentoSlice';
import FormUploadFilePopup from '../components/popup/FormUploadFilePopup';
import IDocumento from '../types/IDocumento';

interface IProps {
    isCreating?: boolean;
}

function Anagrafica(props: IProps) {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const { selectedAnagrafica, newAnagrafica, loading, error } = useAppSelector((state) => state.anagrafica);
    const { loading: loadingDoc, error: errorDoc } = useAppSelector((state) => state.documento);
    const { idAnagrafica } = useAppSelector((state) => state.auth);

    const [isEditing, setIsEditing] = useState(props.isCreating);
    const [isDisabled, setIsDisabled] = useState(false);
    const [popupErrorMessage, setPopupErrorMessage] = useState<string | null>(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showArchiveConfirm, setShowArchiveConfirm] = useState(false);
    const [showDeleteDocConfirm, setShowDeleteDocConfirm] = useState(false);
    const [showUploadDoc, setShowUploadDoc] = useState(false);
    const [deleteCompleted, setDeleteCompleted] = useState(false);
    const [archiveCompleted, setArchiveCompleted] = useState(false);
    const [unarchiveCompleted, setUnarchiveCompleted] = useState(false);
    const [deleteDocCompleted, setDeleteDocCompleted] = useState(false);
    const [selectedDocumento, setSelectedDocumento] = useState<IDocumento | null>(null);
    const [formData, setFormData] = useState<IAnagrafica>(exampleAnagrafica);

    useEffect(() => {
        if (id) {
            dispatch(fetchAnagraficaById(Number(id)));
        }
    }, [id, dispatch, idAnagrafica]);

    useEffect(() => {
        if (selectedAnagrafica && !error && !props.isCreating) {
            setFormData(selectedAnagrafica);
        }
    }, [selectedAnagrafica, error, props.isCreating]);

    useEffect(() => {
        setIsDisabled(
            (!props.isCreating && (selectedAnagrafica?.IsArchiviato ?? false)) ||
            ((selectedAnagrafica?.IsEducatore ?? false) && (selectedAnagrafica?.Id ?? -1) !== idAnagrafica)
        );
    }, [props, selectedAnagrafica, idAnagrafica]);

    useEffect(() => {
        if (deleteCompleted && !error) {
            setDeleteCompleted(false);
            navigate('/');
        }
    }, [deleteCompleted, error, navigate]);

    useEffect(() => {
        if (archiveCompleted && !error) {
            setArchiveCompleted(false);
            navigate('/');
        }
    }, [archiveCompleted, error, navigate]);

    useEffect(() => {
        if (unarchiveCompleted && !error && id) {
            dispatch(fetchAnagraficaById(Number(id)));
        }
    }, [unarchiveCompleted, error, dispatch, id]);

    useEffect(() => {
        if (deleteDocCompleted && !errorDoc) {
            setShowDeleteDocConfirm(false);
            setDeleteDocCompleted(false);
        }
    }, [deleteDocCompleted, errorDoc]);

    useEffect(() => {
        if (error) {
            setPopupErrorMessage(error);
        } else {
            setPopupErrorMessage(null);
        }
    }, [error]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (name === 'Nome' || name === 'Cognome') {
            formattedValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }

        if (name === 'CF') {
            formattedValue = value.toUpperCase();
        }

        setFormData({
            ...formData,
            [name]: formattedValue,
        });
    };

    const handleSave = () => {
        if (!formData.Nome || !formData.Cognome || !formData.DataNascita || !formData.CF || !formData.Residenza || !formData.Sesso /* || !formData.Ente */) {
            setPopupErrorMessage('Compilare tutti i campi');
            return;
        }
        if (!isValidCF(formData.CF)) {
            setPopupErrorMessage('Codice Fiscale non valido');
            return;
        }
        if (props.isCreating) {
            dispatch(createAnagrafica(formData))
                .then((result) => {
                    if (createAnagrafica.fulfilled.match(result)) {
                        setIsEditing(false);
                        if (result.payload.Id) {
                            navigate(`/anagrafica/${result.payload.Id}`);
                        }
                    }
                })
                .catch(() => setPopupErrorMessage('Errore durante la creazione'));
        } else if (id) {
            dispatch(updateAnagrafica({ id: Number(id), newAnagrafica: formData }))
                .then((result) => updateAnagrafica.fulfilled.match(result) && setIsEditing(false))
                .catch(() => setPopupErrorMessage('Errore durante l\'aggiornamento'));
        }
    };

    const handleDelete = () => {
        if (id) {
            dispatch(deleteAnagrafica(Number(id)))
                .then(() => setDeleteCompleted(true))
                .catch(() => setDeleteCompleted(false));
        }
    };

    const handleDeleteDocClick = (documento: IDocumento) => {
        setShowDeleteDocConfirm(true);
        setSelectedDocumento(documento);
    }

    const handleDeleteDoc = () => {
        if (id && selectedDocumento) {
            dispatch(deleteDocumento({ idAnagrafica: Number(id), documento: selectedDocumento }))
                .then(() => setDeleteDocCompleted(true))
                .catch(() => setDeleteDocCompleted(false));
        }
    };

    const handleArchive = () => {
        if (id) {
            dispatch(archiveAnagrafica(Number(id)))
                .then((e) => setArchiveCompleted(true))
                .catch(() => setArchiveCompleted(false));
        }
    };

    const handleUnarchive = () => {
        if (id) {
            dispatch(unarchiveAnagrafica(Number(id)))
                .then((e) => setUnarchiveCompleted(true))
                .catch(() => setUnarchiveCompleted(false));
        }
    };

    const handleAnnulla = () => {
        if (props.isCreating) {
            navigate('/');
        } else {
            setIsEditing(false);
            setFormData(selectedAnagrafica || formData);
        }
    }

    return (
        <div className='container'>
            {(loading || loadingDoc) && <Loading />}

            {showDeleteConfirm && (
                <Popup
                    type='error'
                    message="Sei sicuro di voler eliminare l'anagrafica? Verranno eliminate tutte le giornate e l'eventuale account associato. I dati non saranno recuperabili"
                    onClose={() => setShowDeleteConfirm(false)}
                    onConfirm={handleDelete}
                />
            )}

            {showArchiveConfirm && (
                <Popup
                    type='warning'
                    message="Sei sicuro di voler archiviare l'anagrafica? Verrà impostata in uno stato di sola lettura"
                    onClose={() => setShowArchiveConfirm(false)}
                    onConfirm={handleArchive}
                />
            )}

            {showDeleteDocConfirm && (
                <Popup
                    type='error'
                    message="Sei sicuro di voler eliminare il documento? L'operazione è irreversibile"
                    onClose={() => setShowDeleteDocConfirm(false)}
                    onConfirm={handleDeleteDoc}
                />
            )}

            <FormUploadFilePopup
                message='Carica un documento'
                show={showUploadDoc}
                onClose={() => setShowUploadDoc(false)}
            />

            {popupErrorMessage && <Popup type='error' message={popupErrorMessage} onClose={() => setPopupErrorMessage(null)} />}

            {(!loading && !loadingDoc) && (selectedAnagrafica || props.isCreating) && (
                <>
                    <AgendaNavbar isCreating={props.isCreating} />

                    <div className='grid grid-cols-11 gap-10'>

                        <section className='col-span-6 xl:col-span-7 flex flex-col gap-4 bg-white border border-gray-300 rounded-2xl rounded-tl-none shadow-md p-6'>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='nome'>Nome</label>
                                <input type='text' id='nome' name='Nome' className='col-span-3' disabled={!isEditing} value={formData.Nome} onChange={handleChange} />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='cognome'>Cognome</label>
                                <input type='text' id='cognome' name='Cognome' className='col-span-3' disabled={!isEditing} value={formData.Cognome} onChange={handleChange} />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='data_nascita'>Data di nascita</label>
                                <input type='date' id='data_nascita' name='DataNascita' className='col-span-3' disabled={!isEditing} value={formData.DataNascita} max={todayStr()} onChange={handleChange} />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='cf'>Codice Fiscale</label>
                                <input type='text' id='cf' name='CF' maxLength={16} className='col-span-3' disabled={!isEditing} value={formData.CF} onChange={handleChange} />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='residenza'>Residenza</label>
                                <input type='text' id='residenza' name='Residenza' className='col-span-3' disabled={!isEditing} value={formData.Residenza} onChange={handleChange} />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='sesso'>Sesso</label>
                                {/* <input type='text' id='sesso' name='sesso' /> */}
                                <select id='sesso' name='Sesso' className='col-span-3' disabled={!isEditing} value={formData.Sesso} onChange={handleChange}>
                                    <option value='' hidden></option>
                                    <option value='M'>Maschio</option>
                                    <option value='F'>Femmina</option>
                                </select>
                            </div>

                            {!selectedAnagrafica?.IsEducatore && (<div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='ente'>Ente</label>
                                <input type='text' id='ente' name='Ente' className='col-span-3' disabled={!isEditing} value={formData.Ente || ''} onChange={handleChange} placeholder='(Opzionale)' />
                            </div>)}

                        </section>

                        <section className={`col-span-5 xl:col-span-4 ${props.isCreating ? 'bg-gray-200' : 'bg-white'} border border-gray-300 rounded-2xl rounded-tr-none shadow-md p-6`}>

                            <div className='flex items-center justify-between mb-4'>
                                <h2 className='h2'>Documenti</h2>
                                <button className='btn' disabled={isEditing || isDisabled} onClick={() => setShowUploadDoc(true)}>Carica <MdUploadFile size={16} /></button>
                            </div>

                            {!props.isCreating && (selectedAnagrafica?.Documenti?.length || 0 > 0) ? (
                                <table className='text-sm'>
                                    <thead className='text-sm'>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Data</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedAnagrafica?.Documenti.map((documento) => (
                                            <tr key={documento.Id} className='text-nowrap'>
                                                <td>{documento.Nome}</td>
                                                <td>{documento.Creation?.split('T')[0]}</td>
                                                <td className='flex justify-center gap-1'>
                                                    <button className=''>
                                                        <Link className='btn btn-sm' to={documento.Path.replace("/documents", "/documents/view")} target='_blank'><MdSearch /></Link>
                                                    </button>
                                                    <button className=''>
                                                        <Link className='btn btn-sm btn-outline' to={documento.Path.replace("/documents", "/documents/download")} target='_blank'><MdDownload /></Link>
                                                    </button>
                                                    <button className='btn btn-sm btn-danger' onClick={() => handleDeleteDocClick(documento)}><MdDelete /></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className='italic text-gray-600'>Nessun documento caricato</p>
                            )}

                        </section>

                    </div>

                    <div className='flex items-center justify-between mt-8'>

                        <div className='flex-1'>
                            {isEditing ? (
                                <div className="flex items-center gap-4">
                                    <button className='btn btn-lg btn-primary' disabled={isDisabled} onClick={handleSave}>Salva <MdSave size={20} /></button>
                                    <button className='btn btn-lg' disabled={isDisabled} onClick={handleAnnulla}>Annulla <MdCancel size={20} /></button>
                                </div>
                            ) : (
                                <button className='btn btn-lg' disabled={isDisabled} onClick={() => setIsEditing(true)}>Modifica <MdEdit size={20} /></button>
                            )}
                        </div>

                        <div className='flex-1 flex justify-center'>
                            {!props.isCreating && selectedAnagrafica?.IsArchiviato ? (
                                <div className="flex items-center gap-4">
                                    <button className='btn btn-lg' onClick={handleUnarchive}>Ripristina <MdOutlineRestorePage size={20} /></button>
                                </div>
                            ) : (
                                <button className='btn btn-lg' disabled={isEditing || (selectedAnagrafica?.IsEducatore && selectedAnagrafica?.Id !== idAnagrafica)} onClick={() => setShowArchiveConfirm(true)}>Archivia <MdArchive size={20} /></button>
                            )}
                        </div>

                        <div className='flex-1 flex justify-end'>
                            <button className='btn btn-lg btn-danger' disabled={isEditing || (selectedAnagrafica?.IsEducatore && selectedAnagrafica?.Id !== idAnagrafica)} onClick={() => setShowDeleteConfirm(true)}>Elimina <MdDelete size={20} /></button>
                        </div>
                    </div>

                </>
            )}

        </div>
    );
}

export default Anagrafica;
