import React, { useEffect, useState } from 'react';
import AgendaNavbar from '../components/AgendaNavbar';
import { MdDelete, MdDownload } from 'react-icons/md';
import { FaFileSignature } from 'react-icons/fa6';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { deleteGiornata, downloadPdfGiornata, fetchAgenda } from '../redux/slices/agendaSlice';
import Loading from '../components/Loading';
import Popup from '../components/popup/Popup';
import PaginationControls from '../components/PaginationControls';

function Storico() {
	const { id } = useParams<{ id: string }>();
	const dispatch = useAppDispatch();
	const { agenda, pages, loading, error } = useAppSelector((state) => state.agenda);

	const [popupErrorMessage, setPopupErrorMessage] = useState<string | null>(null);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [deleteCompleted, setDeleteCompleted] = useState(false);
	const [selectedDate, setSelectedDate] = useState<string | null>(null);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (id) {
			dispatch(fetchAgenda({ id: Number(id), page, limit: 15 }));
		}
	}, [id, dispatch, page]);

	useEffect(() => {
		if (deleteCompleted && !error) {
			setShowDeleteConfirm(false);
			setDeleteCompleted(false);
		}
	}, [deleteCompleted, error]);

	useEffect(() => {
		if (error) {
			setPopupErrorMessage(error);
		} else {
			setPopupErrorMessage(null);
		}
	}, [error]);

	const handleDeleteClick = (date: string) => {
		setShowDeleteConfirm(true);
		setSelectedDate(date);
	}

	const handleDelete = () => {
		if (id && selectedDate) {
			dispatch(deleteGiornata({ id: Number(id), date: selectedDate }))
				.then(() => setDeleteCompleted(true))
				.catch(() => setDeleteCompleted(false));
		}
	};

	const handleDownloadPdfGiornata = async (date: string) => {
		if (id && date) {
			try {
				const blob = await downloadPdfGiornata({ id: Number(id), date });
				const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
				const a = document.createElement('a');
				a.href = url;
				a.download = `giornata-${date}.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			} catch (error) {
				console.error('Errore durante il download del PDF:', error);
			}
		}
	};

	return (
		<div className='container'>

			{showDeleteConfirm && (
				<Popup
					type='error'
					message="Sei sicuro di voler eliminare la giornata? I dati non saranno recuperabili"
					onClose={() => setShowDeleteConfirm(false)}
					onConfirm={handleDelete}
				/>
			)}

			{popupErrorMessage && <Popup type='error' message={popupErrorMessage} onClose={() => setPopupErrorMessage(null)} />}

			<AgendaNavbar />

			<table className='rounded-tl-none'>

				<thead>
					<tr>
						<th>Data</th>
						<th>Sonno</th>
						<th>Uscita</th>
						<th>Fatti significativi</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{loading && (
						<tr>
							<td colSpan={5}>
								{loading && <Loading />}
							</td>
						</tr>
					)}
					{!loading && !error && agenda.map((giornata) => (
						<tr key={giornata.Id}>
							<td>{giornata.Data}</td>
							<td>{giornata.Sonno?.Sveglia != null ? '✅' : '❌'} | {giornata.Sonno?.Letto != null ? '✅' : '❌'}</td>
							<td>{giornata.Uscita?.Data != null || giornata.Uscita?.Tipologia ? (giornata.Uscita?.Data != null && giornata.Uscita?.DataRientro != null ? '✅' : '🕒') : '❌'}</td>
							<td>{"".concat(giornata.FattiSignificativi?.Mattina ?? "", " ", giornata.FattiSignificativi?.Pomeriggio ?? "", " ", giornata.FattiSignificativi?.Sera ?? "").substring(0, 35).concat('...')}</td>
							<td className='flex justify-center gap-2'>
								<button>
									<Link className='btn btn-sm btn-primary' to={`/giornata/${id}/${giornata.Data}`}><FaFileSignature /></Link>
								</button>
								<button className='btn btn-sm btn-outline' onClick={() => handleDownloadPdfGiornata(giornata.Data)}><MdDownload /></button>
								<button className='btn btn-sm btn-danger' onClick={() => handleDeleteClick(giornata.Data)}><MdDelete /></button>
							</td>
						</tr>
					))}
					{!loading && !error && agenda.length === 0 && (
						<tr>
							<td colSpan={5} className='italic text-gray-600'>Nessun risultato trovato...</td>
						</tr>
					)}
				</tbody>
			</table>

			{pages > 1 && <PaginationControls page={page} totalPages={pages} onPageChange={setPage} />}
		</div>
	);
}

export default Storico;
