import React from 'react';
import Anagrafiche from '../components/Anagrafiche';

function Archivio() {
  return (
    <div className=''>
        <Anagrafiche isArchived />
    </div>
  );
}

export default Archivio;
