import React, { useEffect, useState } from 'react';
import { MdAdd, MdClear } from 'react-icons/md';
import { FaAddressCard } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { fetchAnagrafiche, fetchAnagraficheArchived } from '../redux/slices/anagraficaSlice';
import Loading from './Loading';
import { todayStr } from '../utils/functions';
import Popup from './popup/Popup';

interface IProps {
    isArchived?: boolean;
}

function Anagrafiche(props: IProps) {
    const dispatch = useAppDispatch();
    const { anagrafiche, loading, error } = useAppSelector((state) => state.anagrafica);
    const navigate = useNavigate();

    const [popupErrorMessage, setPopupErrorMessage] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (!anagrafiche) {
            dispatch(props.isArchived ? fetchAnagraficheArchived() : fetchAnagrafiche());
        }
    }, [anagrafiche, dispatch]);

    useEffect(() => {
        if (searchTerm) {
            dispatch(props.isArchived ? fetchAnagraficheArchived(searchTerm) : fetchAnagrafiche(searchTerm));
        } else {
            dispatch(props.isArchived ? fetchAnagraficheArchived() : fetchAnagrafiche());
        }
    }, [searchTerm, dispatch]);

    useEffect(() => {
        if (error) {
            setPopupErrorMessage(error);
        } else {
            setPopupErrorMessage(null);
        }
    }, [error]);

    const handleRowClick = (id: number, isEducatore: boolean = false) => {
        navigate(isEducatore || props.isArchived ? `/anagrafica/${id}` : `/giornata/${id}/${todayStr()}`);
    };

    return (
        <div className='container'>

            {popupErrorMessage && <Popup type='error' message={popupErrorMessage} onClose={() => setPopupErrorMessage(null)} />}

            <div className='grid grid-cols-3 items-center mb-6'>
                <div className='col-span-1'>
                    <h1 className='h1'>Anagrafiche</h1>
                </div>
                <div className='col-span-2 flex items-center gap-5'>
                    <div className='w-full flex items-center relative'>
                        <input type='text' placeholder='Cerca...' className='w-full px-4 py-1 border border-gray-300 rounded-lg'
                            value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        {searchTerm !== '' && (
                            <span className='absolute right-3 cursor-pointer' onClick={() => setSearchTerm('')}>
                                <MdClear size={20} />
                            </span>
                        )}
                    </div>
                    <button className='btn !p-0'>
                        <Link className='btn' to={'/anagrafica'}>Nuova <MdAdd size={18} /></Link>
                    </button>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Cognome</th>
                        <th>Nome</th>
                        <th>Codice Fiscale</th>
                        <th>Data Nascita</th>
                        <th>
                            {/* <button className='btn btn-sm btn-outline'><MdDownload /></button> */}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={6}>
                                {loading && <Loading />}
                            </td>
                        </tr>
                    )}
                    {!loading && !error && anagrafiche.map((anagrafica) => (
                        <tr key={anagrafica.Id}
                            className='hover:bg-gray-200 cursor-pointer'
                            onClick={() => handleRowClick(anagrafica.Id, anagrafica.IsEducatore)}
                        >
                            <td>{anagrafica.IsEducatore ? '🎓' : (anagrafica.Sesso === 'M' ? '👦🏻' : '👧🏻')}</td>
                            <td>{anagrafica.Cognome}</td>
                            <td>{anagrafica.Nome}</td>
                            <td>{anagrafica.CF}</td>
                            <td>{anagrafica.DataNascita}</td>
                            <td>
                                <button className='btn btn-sm !p-0' onClick={(e) => e.stopPropagation()}>
                                    <Link className='btn btn-sm' to={`/anagrafica/${anagrafica.Id}`}><FaAddressCard /></Link>
                                </button>
                            </td>
                        </tr>
                    ))}
                    {!loading && !error && anagrafiche.length === 0 && (
                        <tr>
                            <td colSpan={6} className='italic text-gray-600'>Nessun risultato trovato...</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Anagrafiche;
