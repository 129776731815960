export default interface IPasto {
    Id: number;
    Id_Giornata: number;
    Colazione: string | null;
    Pranzo: string | null;
    Cena: string | null;
    MerendaMattino: string | null;
    MerendaPomeriggio: string | null;
    Note: string | null;
    Creation: string;
    Timestamp: string;
}

export const examplePasto: IPasto = {
    Id: 0,
    Id_Giornata: 0,
    Colazione: null,
    Pranzo: null,
    Cena: null,
    MerendaMattino: null,
    MerendaPomeriggio: null,
    Note: null,
    Creation: '',
    Timestamp: '',
}
