import { useState } from 'react';
import { MdCancel, MdCheck, MdClose } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Popup from './Popup';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { uploadDocumento } from '../../redux/slices/documentoSlice';
import Notification from '../Notification';

interface IProps {
    message: string;
    show: boolean;
    onClose: () => void;
}

function FormUploadFilePopup(props: IProps) {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();

    const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);
    const [popupErrorMessage, setPopupErrorMessage] = useState<string | null>(null);
    const [name, setName] = useState('');
    const [file, setFile] = useState<File | null>(null);

    const showNotification = (message: string, type: 'success' | 'error' | 'warning') => {
        setNotification({ message, type });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const handleConfirm = () => {
        if (!name || !file) {
            setPopupErrorMessage('Compilare tutti i campi');
            return;
        }

        if (id) {
            dispatch(uploadDocumento({ idAnagrafica: Number(id), name, file }))
                .then((result) => {
                    if (uploadDocumento.fulfilled.match(result)) {
                        showNotification('Documento caricato con successo', 'success');
                        props.onClose();
                    }
                })
                .catch((error) => {
                    setPopupErrorMessage(error || 'Errore durante il caricamento del documento');
                });
        }
    }

    return (
        <>
            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            {popupErrorMessage && <Popup type='error' message={popupErrorMessage} onClose={() => setPopupErrorMessage(null)} />}

            <div className={`fixed inset-0 flex items-center justify-center bg-black/50 z-[900] ${!props.show && 'hidden'}`}>
                <div className='bg-white rounded-2xl shadow-lg'>
                    <div className={`w-full h-14 bg-gray-300 rounded-t-2xl relative`}>
                        <button className='absolute top-4 right-4 text-black'>
                            <MdClose size={24} onClick={props.onClose} />
                        </button>
                    </div>
                    <div className='flex flex-col gap-4 px-6 pt-4 pb-6'>
                        <p className='text-lg'>{props.message}</p>

                        <form action=''>
                            <div className='flex flex-col gap-3'>
                                <div className='form-element !grid !grid-cols-4 gap-4'>
                                    <label htmlFor='nome'>Nome</label>
                                    <input type='text' id='nome' name='Nome' className='col-span-3' onChange={(e) => setName(e.target.value)} />
                                </div>

                                <div className='form-element !grid !grid-cols-4 gap-4'>
                                    <label htmlFor='file'>Documento</label>
                                    <input type='file' id='file' name='file' className='col-span-3' onChange={handleFileChange} />
                                </div>
                            </div>
                        </form>

                        <div className='w-full flex items-center justify-between gap-10 mt-3'>
                            <button
                                onClick={props.onClose}
                                className='btn'
                            >
                                Annulla <MdCancel size={18} />
                            </button>
                            <button
                                onClick={handleConfirm}
                                className='btn btn-primary'
                            >
                                Conferma <MdCheck size={18} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormUploadFilePopup;