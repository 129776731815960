import React from 'react';
import { MdCancel, MdCheck, MdClose } from 'react-icons/md';

interface IProps {
    type: 'success' | 'error' | 'warning' | 'info';
    message: string;
    onClose: () => void;
    onConfirm?: () => void;
}

function Popup(props: IProps) {
    const getBgColorByType = () => {
        switch (props.type) {
            case 'success':
                return 'bg-green-600';
            case 'error':
                return 'bg-red-600';
            case 'warning':
                return 'bg-yellow-600';
            case 'info':
                return 'bg-cyan-600';
            default:
                return 'bg-gray-600';
        }
    }

    return (
        <div className='fixed inset-0 flex items-center justify-center bg-black/50 z-[999]'>
            <div className='max-w-96 bg-white rounded-2xl shadow-lg'>
                <div className={`w-full h-14 ${getBgColorByType()} rounded-t-2xl relative`}>
                    <button className='absolute top-4 right-4 text-white'>
                        <MdClose size={24} onClick={props.onClose} />
                    </button>
                </div>
                <div className='flex flex-col items-end gap-6 px-6 pt-4 pb-6'>
                    <p className='text-lg'>{props.message}</p>

                    {props.onConfirm && (
                        <div className="w-full flex items-center justify-between">
                            <button
                                onClick={props.onClose}
                                className='btn'
                            >
                                Annulla <MdCancel size={18} />
                            </button>
                            <button
                                onClick={props.onConfirm}
                                className='btn btn-primary'
                            >
                                Conferma <MdCheck size={18} />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Popup;
