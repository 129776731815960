import React, { useEffect, useState } from 'react';
import { MdSave, MdSearch } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import Popup from '../components/popup/Popup';
import { fetchAccount, updateAccount } from '../redux/slices/accountSlice';
import IAccount, { exampleAccount } from '../types/IAccount';
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { isValidPassword } from '../utils/functions';
import Notification from '../components/Notification';

function Profilo() {
    const dispatch = useAppDispatch();
    const { selectedAccount, loading, error } = useAppSelector((state) => state.account);

    // const [isEditing, setIsEditing] = useState(false);
    const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);
    const [popupErrorMessage, setPopupErrorMessage] = useState<string | null>(null);
    const [formData, setFormData] = useState<IAccount>(exampleAccount);
    const [showPassword, setShowPassword] = useState<{ old: boolean, new: boolean, newConfirm: boolean }>({ old: false, new: false, newConfirm: false });

    useEffect(() => {
        // if (!selectedAccount) {
            dispatch(fetchAccount());
        // }
    }, [/* selectedAccount,  */dispatch]);

    useEffect(() => {
        if (selectedAccount && !error) {
            setFormData(selectedAccount);
        }
    }, [selectedAccount, error]);

    useEffect(() => {
        if (error) {
            setPopupErrorMessage(error);
        } else {
            setPopupErrorMessage(null);
        }
    }, [error]);

    const showNotification = (message: string, type: 'success' | 'error' | 'warning') => {
        setNotification({ message, type });
    };

    const handleChangeDatiAnagrafici = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Dividi il nome per individuare la struttura annidata, se presente
        const nameParts = name.split('.'); // Es: 'Sonno.Sveglia'

        setFormData((prevData) => {
            // Clona l'oggetto corrente di formData
            const updatedData = { ...prevData };

            // Naviga attraverso l'oggetto fino all'ultimo campo
            let currentLevel: any = updatedData;
            for (let i = 0; i < nameParts.length - 1; i++) {
                currentLevel[nameParts[i]] = { ...currentLevel[nameParts[i]] }; // Clona ogni livello per evitare la mutazione dello stato originale
                currentLevel = currentLevel[nameParts[i]];
            }

            if (nameParts[nameParts.length - 1] === 'Nome' || nameParts[nameParts.length - 1] === 'Cognome') {
                formattedValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
            }

            // Aggiorna il valore del campo specifico
            currentLevel[nameParts[nameParts.length - 1]] = formattedValue;

            return updatedData;
        });
    };

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue = value;

        setFormData({
            ...formData,
            [name]: formattedValue,
        });
    };

    const handleShowPassword = (field: 'old' | 'new' | 'newConfirm') => {
        setShowPassword((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const handleSave = () => {
        if (!formData.Password) {
            setPopupErrorMessage('Inserisci la password attuale');
            return;
        }
        if (!formData.NewPassword) {
            setPopupErrorMessage('Inserisci la nuova password');
            return;
        }
        if (!formData.NewPasswordConfirm) {
            setPopupErrorMessage('Conferma la nuova password');
            return;
        }
        if (!isValidPassword(formData.NewPassword)) {
            setPopupErrorMessage('La nuova password non rispetta i requisiti minimi: almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale, per un totale di almeno 8 caratteri');
            return;
        }
        if (formData.NewPassword !== formData.NewPasswordConfirm) {
            setPopupErrorMessage('La nuova password non corrisponde con la conferma');
            return;
        }
        dispatch(updateAccount(formData))
            .then((result) => {
                if (updateAccount.fulfilled.match(result)) {
                    setFormData({
                        ...formData,
                        Password: '',
                        NewPassword: '',
                        NewPasswordConfirm: '',
                    });
                    showNotification('Password aggiornata con successo', 'success');
                }
                else {
                    showNotification('Errore durante l\'aggiornamento', 'warning');
                }
            })
            .catch(() => showNotification('Errore durante l\'aggiornamento', 'error'));
    };

    return (
        <div className='container'>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            {popupErrorMessage && <Popup type='error' message={popupErrorMessage} onClose={() => setPopupErrorMessage(null)} />}

            <div className='grid grid-cols-5 gap-10'>

                <section className='col-span-3 flex flex-col gap-4 bg-white border border-gray-300 rounded-2xl shadow-md p-6'>

                    <h2 className='h2'>Dati anagrafici</h2>

                    {loading && <Loading height='300px' />}

                    {!loading && !error && (
                        <>
                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='nome'>Nome</label>
                                <input type='text' id='nome' name='Anagrafica.Nome' className='col-span-3' disabled title='Modifica questo campo dalla pagina anagrafica' value={formData.Anagrafica.Nome} />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='cognome'>Cognome</label>
                                <input type='text' id='cognome' name='Anagrafica.Cognome' className='col-span-3' disabled title='Modifica questo campo dalla pagina anagrafica' value={formData.Anagrafica.Cognome} />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='email'>Email</label>
                                <input type='email' id='email' name='Email' className='col-span-3' disabled title='Campo non modificabile' value={formData.Email} />
                            </div>
                        </>
                    )}

                    {/* <div className='flex items-center justify-between mt-4'>

                        <button className='btn btn-lg' disabled={isEditing} onClick={() => setIsEditing(true)}>Modifica <MdEdit size={20} /></button>

                        <button className='btn btn-lg' disabled={!isEditing} onClick={}>Salva <MdSave size={20} /></button>

                    </div> */}
                    {selectedAccount && (
                        <div className='flex items-center justify-center mt-4'>

                            <button className=''>
                                <Link to={`/anagrafica/${selectedAccount.Id_Anagrafica}`} className='btn btn-lg'>
                                    Mostra <MdSearch size={20} />
                                </Link>
                            </button>

                        </div>
                    )}

                </section>

                <section className='col-span-2 flex flex-col gap-4 bg-white border border-gray-300 rounded-2xl shadow-md p-6'>

                    <h2 className='h2'>Modifica password</h2>

                    <div className='form-element !grid !grid-cols-2 gap-4 relative'>
                        <label htmlFor='password_attuale'>Password attuale</label>
                        <input type={showPassword.old ? 'text' : 'password'} id='password_attuale' name='Password' value={formData.Password ?? ''} onChange={handleChangePassword} autoComplete='new-password' />
                        <span className='absolute right-3 cursor-pointer' onClick={() => handleShowPassword('old')}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>

                    <div className='form-element !grid !grid-cols-2 gap-4 relative'>
                        <label htmlFor='nuova_password'>Nuova Password</label>
                        <input type={showPassword.new ? 'text' : 'password'} id='nuova_password' name='NewPassword' value={formData.NewPassword ?? ''} onChange={handleChangePassword} />
                        <span className='absolute right-3 cursor-pointer' onClick={() => handleShowPassword('new')}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>

                    <div className='form-element !grid !grid-cols-2 gap-4 relative'>
                        <label htmlFor='conferma_password'>Conferma Password</label>
                        <input type={showPassword.newConfirm ? 'text' : 'password'} id='conferma_password' name='NewPasswordConfirm' value={formData.NewPasswordConfirm ?? ''} onChange={handleChangePassword} />
                        <span className='absolute right-3 cursor-pointer' onClick={() => handleShowPassword('newConfirm')}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>

                    <div className='flex items-center justify-center mt-4'>

                        <button className='btn btn-lg' disabled={!formData.Password || !formData.NewPassword || !formData.NewPasswordConfirm} onClick={handleSave}>Salva <MdSave size={20} /></button>

                    </div>

                </section>

            </div>

        </div>
    );
}

export default Profilo;
